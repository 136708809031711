import React from "react";
import { graphql } from 'gatsby'
import Header from "../components/Header/Header"
import TeamDetailsPage from '../components/StaticPage/AboutPage/AboutPage'
import Footer from "../components/Footer/Footer"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";

const NewsTemplate = (props) => {
  const GQLTeams = props.data.glstrapi?.peoples;
  return(
    <div className="team-details-main">
      <Header/>
        <TeamDetailsPage peoples={GQLTeams} />
      <Footer/>
    </div>
  )
}

export default NewsTemplate


export const pageQuery = graphql`
  query TeamsQuery($slug: ID!) {
    glstrapi {
      peoples(where: {id: $slug} ){
          id  
          Name
          Designation
          Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(width: 600, breakpoints: 600)
              }
            }
          }
          Meta_Title
          Meta_Description
          Phone
          Email
          About
          imagetransforms
      }
    }
  }
`